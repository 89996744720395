<template>
  <div class="login-box">
    <div class="login-box-2">
      <div class="img">
        <img :src="loginImg">
      </div>
      <div class="form">
        <el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="login-form" label-position="top">
          <div class="login-form-title">
            用户注册
          </div>
          <el-form-item prop="account" label="手机号">
            <el-input v-model="loginForm.account" placeholder="请输入您的帐号/手机号" autocomplete="off">
              <template slot="prefix">
                <svg-icon icon-class="user"/>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="password" label="密码">
            <el-input v-model="loginForm.password" :type="passWordType?'password':'text'" placeholder="请输入密码" autocomplete="off">
              <template slot="prefix">
                <svg-icon icon-class="password"/>
              </template>
              <template slot="suffix">
                <svg-icon :icon-class="passWordType?'eye':'eye-open'" @click="passWordShow"/>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="password_confirmation" label="确认密码">
            <el-input v-model="loginForm.password_confirmation" :type="passWordType?'password':'text'" placeholder="请再次输入密码" >
              <template slot="prefix">
                <svg-icon icon-class="password"/>
              </template>
              <template slot="suffix">
                <svg-icon :icon-class="passWordType?'eye':'eye-open'" @click="passWordShow"/>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item class="login-form-submit">
            <div>
              <img :src="loginImg2" style="margin-right: 5px">
              已有账号，去
              <span @click="toLogin">登陆</span>
            </div>
            <el-button type="primary" @click="submitForm('loginForm')">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>


  </div>
</template>

<script>
import {register} from "../../api/module/login";

export default {
  components: {},
  props: [],
  data() {
    return {
      //登录页图片地址
      loginImg:require('@/views/login/img/register.png'),
      loginImg2:require('@/views/login/img/login_06.png'),
      //密码是否显示
      passWordType: true,
      //数据
      loginForm: {
        account: '',
        mobile:'',
        password: '',
        password_confirmation: '',
      },
      //验证
      loginRules: {
        account: [
            {required: true, trigger: 'blur', message: '请输入账号',},
          { pattern: /^1[3456789]\d{9}$/, message: '账号为手机号码' }
        ],
        password: [
          {required: true, trigger: 'blur', message: '请输入密码'},
          {min: 6, max: 12, trigger: 'blur', message: '长度在 6 到 12 个字符'}
        ],
        password_confirmation: [
          {required: true, trigger: 'blur', message: '请再次输入密码'},
          {min: 6, max: 12, trigger: 'blur', message: '长度在 6 到 12 个字符'}
        ]
      },
    };
  },
  mounted() {
  },
  methods: {
    //去注册
    toLogin(){
      //命名的路由
      this.$router.push('./')
    },
    //密码是否显示
    passWordShow() {
      this.passWordType = !this.passWordType;
    },
    //提交
    submitForm() {

      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.loginForm.mobile = this.loginForm.account;
          if (this.loginForm.password!=this.loginForm.password_confirmation){
            this.$message({
              type: 'error',
              message: "两次密码输入不一致"
            });
            return
          }
          register(this.loginForm).then((res) =>{
            this.$message({
              type: 'success',
              message: res.msg
            });
            localStorage.setItem('admin_token', res.data.access_token);
            this.$router.push('./register/perfectInfo')

          })
        } else {
          return false;
        }
      });
    }
  },

};
</script>
<style lang="scss">
.login-form {
  .el-input__inner {
    padding: 12px 35px 12px 35px;
    font-size: 14px;
    border: 0;
    height: 40px;
    line-height: 40px;
  }

  .el-input--small {
    color: #454545;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }

  .el-input__prefix {
    line-height: 40px;
    padding-left: 8px;
  }

  .el-input__suffix {
    line-height: 40px;
    padding-right: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .login-form-submit {
    .el-form-item__content{
      position: relative;
      div{
        display: flex;
        align-items: center;
        position: absolute;
        margin-top: 5px;
        span{
          color: #1add97;
        }
        span:hover{
          cursor: pointer;
        }
      }
      .el-button {
        position: absolute;
        right: 0;
        width: 25%;
        border: 0;
        padding: 15px 0;
        font-size: 16px;
        background: #1add97;
      }
    }
  }

  .el-form-item__label{
    padding: 0;
  }

}
</style>
<style lang="scss" scoped>
.login-box {
  height: 100%;
  background: #5E47F5  url("../login/img/bg.png") no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  &-2 {
    min-width: 1200px;
    display: flex;
    height: 390px;
    border-radius: 10px;
    overflow: hidden;
    .img{
      width: 50%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .form{
      width: 50%;
      height: 100%;
      background: white;
    }
  }
}

.login-form {
  width: 90%;
  margin: 30px auto;

  &-title {
    text-align: center;
    font: {
      weight: bold;
      size: 26px;
    };
  }

  &-tips {
    color: #fff;
    & span:nth-of-type(1) {
      margin-right: 12px;
    }
  }
}


</style>
